import ABOUT1 from "../assets/about-1.png"
import ABOUT2 from "../assets/about-2.jpg"
import ABOUT3 from "../assets/about-3.png"
import ABOUT4 from "../assets/home.jpg"
import AboutData from "./AboutData"
import "./AboutStyles.css"

const About = () => {
    return (
        <div className="about">
            <h1>نبذة عامة</h1>
            <p>نحن نبني حلول الغد الآن: اكتشفوا خبراتنا في مجالات الهندسة والتطوير والتشييد والبناء</p>

        <AboutData 
        className="first-text-reverse"
        heading="من نحن؟"
        text="نحن شركة سعودية رائدة في مجال الهندسة والمقاولات، متخصصون في تقديم حلول هندسية متطورة وخدمات تطويرية شاملة. يتميز فريقنا بخبرة عملية وأكاديمية عالية مما يعزز قدرتنا على تنفيذ الأعمال بكفاءة. في الإنجاز المستقبلي، نسعى لتحقيق الريادة في مشاريع الهندسة والمقاولات، متبعين بذلك أعلى معايير الجودة والكفاءة. نحن نستفيد من شبكتنا الواسعة من المطورين والمشرفين المحليين والإقليميين والدوليين، ما يحفزنا على الاستمرار في السعي لاستخدام التقنيات الأكثر ابتكاراً لتعزيز إنتاجيتنا مما يسهم في جعل أعمالنا أكثر كفاءة"
        img1={ABOUT1}
        img2={ABOUT2}

        />

        <AboutData 
        className="first-text"
        heading="تقدم مستمر"
        text="نلتزم بتعزيز الإنتاج المحلي من خلال تقديم حلول هندسية رائدة وفنية مستدامة. خبراتنا الواسعة وقدراتنا الفريدة تساهم في تحقيق أهداف رؤية 2030 ودفع الابتكار في القطاع."
        img1={ABOUT3}
        img2={ABOUT4}

        />

        </div>
    )
}

export default About
