import "./ServicesHome.css"
import ServicesHomeData from "./ServicesHomeData"
import SERV1 from "../assets/serv-1.jpg"
import SERV2 from "../assets/servi-2.jpg"
import SERV3 from "../assets/serv-3.jpg"
import { Link } from 'react-router-dom'

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
} 

function ServicesHome() {
    return (
        <div className="serviceshome">
            <h1>خدماتنا</h1>
            <p>نقدم مجموعة شاملة من الخدمات الهندسية والاستشارية، مع التركيز بشكل خاص على العروض الأساسية التالية</p>
            <div className="serviceshomecard">
                <ServicesHomeData 
                    image={SERV1}
                    heading="الدراسات الرأسمالية"
                />
                <ServicesHomeData 
                    image={SERV2}
                    heading="أنشطة الاستشارات الهندسية والمعمارية"
                />
                <ServicesHomeData 
                    image={SERV3}
                    heading="إدارة مشاريع التشييد والبناء"
                />
            </div>
            <div className="button-services">
            <Link to="/services" onClick={scrollToTop}><button>تعرف أكثر على خدماتنا</button></Link>
            </div>
        </div>
    );
}

export default ServicesHome;
