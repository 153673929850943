export const MenuItems = [
    {
        title: "",
        url: "tel://00966550870588",
        cName: "nav-links-mobile",
        icon: "fa-solid fa-phone"
    },
    {
        title: "تواصل معنا",
        url: "/contact",
        cName: "nav-links",
        icon: "fa-solid fa-address-book"
    },
    {
        title: "العملاء",
        url: "/clients",
        cName: "nav-links",
        icon: "fa-solid fa-handshake"
    },
    {
        title: "الخدمات",
        url: "/services",
        cName: "nav-links",
        icon: "fa-solid fa-briefcase"
    },
    {
        title: "الرئيسية",
        url: "/",
        cName: "nav-links",
        icon: "fa-solid fa-house-user"
    },
];
