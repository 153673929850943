import Navbar from "../components/Navbar"
import Hero from "../components/Hero"
import ContactImg from "../assets/home.jpg"
import Footer from "../components/Footer"
import ContactForm from "../components/ContactForm"


function Contact (){
    return(
        <>
        <Navbar />
        <Hero
           cName="hero-mid"
           heroImg={ContactImg}
           title="تواصل معنا"
           btnClass="hide"
        />
        <div id="contact-section"><ContactForm /></div>
        <Footer />
       </>
    )
}

export default Contact;