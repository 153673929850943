import "./ServicesSec.css"
import ServiceSecData from "./ServicesSecData"
import SERV1 from "../assets/serv-1.jpg"
import SERV2 from "../assets/servi-2.jpg"
import SERV3 from "../assets/serv-3.jpg"
import SERV4 from "../assets/serv-4.jpg"
import SERV5 from "../assets/serv-5.jpg"
import SERV6 from "../assets/serv-6.jpg"

function ServicesSec() {
    return (
        <div className="servicessec">
            <h1>خدماتنا</h1>
            <p>نقدم مجموعة شاملة من الخدمات الهندسية والاستشارية، مع التركيز الخاص على العروض الأساسية التالية</p>
            <div className="servicesseccard">
                <ServiceSecData 
                image={SERV1}
                heading="الدراسات الرأسمالية"
                />
                <ServiceSecData 
                image={SERV2}
                heading="أنشطة الاستشارات الهندسية والمعمارية"
                />
                <ServiceSecData 
                image={SERV3}
                heading="إدارة مشاريع التشييد والبناء"
                />
                <ServiceSecData 
                image={SERV4}
                heading="الاستشارات في مجال التسويق وتطوير الأعمال"
                />
                <ServiceSecData 
                image={SERV5}
                heading="الاستشارات في مجال الأنظمة الذكية وأنظمة الامان"
                />
                <ServiceSecData 
                image={SERV6}
                heading="الاستشارات في مجال التخطيط الحضري"
                />
            </div>
        </div>
    )
}

export default ServicesSec
