import ClientCarousel from "./ClientCarousel"
import "./ClientSec.css"

function ClientSec() {
    return(
        <div className="clientsec-container">
            <h1>أعمال تنفيذنا للمشاريع الهندسية والفنية</h1>
            <p>تشمل إنجازاتنا التراكمية مجموعة متنوعة من المشاريع بما في ذلك الأعمال المدنية أو المعمارية، والأعمال الكهربائية، والأعمال الميكانيكية، وأنظمة المراقبة الأمنية، والأعمال التوريد العامة</p>
            <h1>أعمالنا في البناء والتطوير للخطط الاستراتيجية والتشغيلية</h1>
            <p>تشمل إنجازاتنا التراكمية تطوير وبناء مشاريع تشغيلية مختلفة، وإعداد خطط استراتيجية لكيانات مؤسسية متنوعة، وإنشاء واعتماد نموذج عمل جديد، وتطوير نماذج لعمليات الأعمال، وبناء وتنفيذ البنية التحتية للتشغيل التلقائي لقواعد البيانات</p> 
        <div>

        <h1 className="carousel-title">عملاءنا</h1>

        <ClientCarousel />

        </div>
        
        </div>
    )

}

export default ClientSec
