import "./TeamStyles.css";
import TeamData from "./TeamData"
import TEAM1 from "../assets/team-1.png"
import TEAM2 from "../assets/team-2.png"
import TEAM3 from "../assets/team-3.png"

function Team() {
    return (
        <div className="team">
            <h1>تعرف على خبرائنا</h1>
            <p>اكتشف خبراتنا في تطوير وإدارة المشاريع وأعمال الاستشارات الاستراتيجية</p>
            <div className="teamcard">
                <TeamData 
                image={TEAM1}
                heading="قسم المشاريع"
                text="يتألف فريق المشاريع من مجموعة من الخبراء في مجالات متعددة، لتخطيط وتطوير مشاريع الأعمال أو الخطط الاستراتيجية للمشاريع الهندسية وتنفيذها، وفقًا للممارسات المحلية والإقليمية والدولية. بالإضافة إلى ذلك، نعتمد على عدد من الخبراء من حلفائنا وشركاءنا لضمان النجاح ضمن نطاق المشروع. نحن نسعى دائمًا لتقديم حلول ذات جودة، استنادًا إلى المواصفات المطلوبة الموضحة في وثائق المشروع. وعلاوة على ذلك، سيتم تجنيد بعض خبرائنا لتنفيذ وتطوير حلول مختلفة ضمن نطاق المشروع."
                />
                <TeamData 
                image={TEAM2}
                heading="المستشار الرئيسي"
                text="الخلفية المهنية: مطور متمرس بخبرة تزيد عن 15 عامًا في القطاعات الحكومية والخاصة، يشغل منصب رئيس مجلس إدارة أو مدير تنفيذي للعديد من الشركات الخاصة، مؤسس لمجموعة من العلامات التجارية الهندسية الوطنية. حاصل على درجة البكالوريوس في الهندسة المدنية، مع العديد من الأدوار الإدارية والقيادية في القطاعات الحكومية والخاصة. لعب دور حيوي في إنشاء عدة شركات ومشاريع، مع مساهمات عديدة في البرامج التدريبية والتعليمية خبير ماهر في خمسة محاور استراتيجية من رؤية 2020 ورؤية 2030 للمملكة."
                />
                <TeamData 
                image={TEAM3}
                heading="مستشار تطوير"
                text="الخلفية المهنية: مطور متمرس بخبرة تزيد عن 7 سنوات، يعمل كمستشار لتطوير الأعمال في العديد من الشركات الخاصة. حاصل على درجة الماجستير في إدارة المشاريع بتفوق، ودرجة البكالوريوس في نفس المجال. متخصص في إدارة المشاريع وبرامج الهندسة التنموية لمشاريع البنية التحتية الحكومية، يعمل على البرامج الاستراتيجية في ضوء التنظيمات والهياكل والترتيبات التنظيمية لمختلف الشركات التابعة للحكومة، وكذلك الشركات الخاصة. معتمد كمحترف في إدارة المشاريع وحاصل على شهادات منتج معتمدة."
                />
            </div>
        </div>
    );
}

export default Team;
